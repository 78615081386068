import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import {
  acceptUserInvite,
  getUserInvite,
  LoadingStateEnum,
} from "../api_service";
import { Account, User, UserInvitationAcceptFormInput } from "../types";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  },
  formField: {
    margin: "5px",
  },
});

interface userInvitationAcceptFormState extends UserInvitationAcceptFormInput {
  errors: { [K in keyof UserInvitationAcceptFormInput]?: string | null };
}

const userInvitationAcceptFormInitialValues: userInvitationAcceptFormState = {
  userFullname: "",
  userPassword: "",
  errors: {},
};

type formKeys = keyof UserInvitationAcceptFormInput;

const userInvitationAcceptFormReducer = (
  state: userInvitationAcceptFormState,
  action: {
    name: formKeys | "RESET" | "ERROR";
    value: string | { [k: string]: string };
  }
) => {
  if (action.name === "RESET") {
    return { ...userInvitationAcceptFormInitialValues };
  }

  if (action.name === "ERROR") {
    return {
      ...state,
      errors: {
        ...state.errors,
        ...(action.value as userInvitationAcceptFormState["errors"]),
      },
    };
  }

  return {
    ...state,
    [action.name]: action.value,
    errors: { ...state.errors, [action.name]: null },
  };
};

function UserInvitationContainer({ inviteToken }: { inviteToken: string }) {
  const [fetchState, setFetchState] = React.useState(LoadingStateEnum.INIT);
  const classes = useStyles();
  const [userInviteAccepting, setUserInviteAccepting] = React.useState(false);
  const [invitedUser, setInvitedUser] = React.useState<User | null>(null);
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["AUTH_TOKEN"]);
  const [currentAccount, setCurrentAccount] = React.useState<Account | null>(
    null
  );
  const [authToken, setAuthToken] = React.useState<string | null>(null);
  const [formState, formDispatch] = React.useReducer(
    userInvitationAcceptFormReducer,
    userInvitationAcceptFormInitialValues
  );

  React.useEffect(() => {
    setFetchState(LoadingStateEnum.FETCHING);
    getUserInvite(inviteToken).then((resp) => {
      // set the current user invitation values
      setInvitedUser(resp.user);
      setAuthToken(resp.token);
      setFetchState(LoadingStateEnum.RECEIVED);
      formDispatch({ name: "userFullname", value: resp.user.fullname });
    });
  }, []);

  if (invitedUser === null) {
    // TODO: make this nicer
    return <p>loading...</p>;
  }

  if (fetchState !== LoadingStateEnum.RECEIVED) {
    // TODO: make this nicer
    return <p>loading...</p>;
  }
  return (
    <div>
      <Typography variant="h1">Complete Invitation</Typography>
      <div className={classes.root}>
        <TextField
          disabled
          className={classes.formField}
          variant="filled"
          id="user-invite-email"
          required
          label="User Email"
          name="user_email"
          value={invitedUser.email}
        />
        <TextField
          disabled
          className={classes.formField}
          variant="filled"
          id="user-invite-user-role"
          required
          label="User Role"
          name="user_role"
          value={invitedUser.role}
        />
        <TextField
          className={classes.formField}
          disabled={userInviteAccepting}
          variant="filled"
          id="user-invite-fullname"
          required
          label="User Name"
          name="user_fullname"
          value={formState.userFullname}
          error={!!formState.errors.userFullname}
          helperText={
            formState.errors.userFullname || "Update your name if necessary"
          }
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            formDispatch({ name: "userFullname", value: evt.target.value })
          }
        />
        <TextField
          type="password"
          disabled={userInviteAccepting}
          className={classes.formField}
          variant="filled"
          id="user-invite-password"
          required
          label="Password"
          name="user_password"
          value={formState.userPassword}
          error={!!formState.errors.userPassword}
          helperText={formState.errors.userPassword || "Select your password"}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            formDispatch({ name: "userPassword", value: evt.target.value })
          }
        />

        <Button
          className={classes.formField}
          variant="contained"
          fullWidth
          color="primary"
          disabled={userInviteAccepting}
          onClick={() => {
            setUserInviteAccepting(true);
            acceptUserInvite(inviteToken, authToken || "", formState)
              .then((resp) => {
                // set the auth token as a cookie and redirect to dashboard
                setUserInviteAccepting(false);
                setCookie("AUTH_TOKEN", authToken, {
                  path: "/",
                  maxAge: 3600,
                });
                formDispatch({ name: "RESET", value: "" });
                history.replace("/");
              })
              .catch((error) => {
                setUserInviteAccepting(false);
                if (
                  error &&
                  error.__type &&
                  error.__type === "PYDANTIC_ERROR"
                ) {
                  // abstract this out!
                  formDispatch({
                    name: "ERROR",
                    value: Object.fromEntries(
                      Object.entries(error).filter(([k, v]) => k !== "__type")
                    ) as { [k: string]: string },
                  });
                }
              });
          }}
        >
          Complete Invitation
        </Button>
      </div>
    </div>
  );
}

export default UserInvitationContainer;
