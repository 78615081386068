/*
    Contains the common elements present in all screens
*/
import { createStyles, Theme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useCookies } from "react-cookie";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";
import { loggedOut } from "../actions";
import { createStripePortalSession } from "../api_service";
import AppContext from "../app_context";
import { UserRoleEnum } from "../types";

const styles = (theme: Theme) =>
  createStyles({
    headerTitle: {
      minWidth: "max-content",
    },
    headerButton: {
      minWidth: "max-content",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolBarActionButton: {
      marginLeft: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    circularProgress: {
      position: "relative",
      right: "50%",
      verticalAlign: "text-top",
      color: "#c84545",
    },
  });

export interface DashboardContainerProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
}

const DashboardContainer: React.FC<DashboardContainerProps> = ({
  children,
  classes,
}) => {
  const { state, dispatch } = React.useContext(AppContext);
  const { userDetails } = state;
  const [
    isRedirectingToStripePortal,
    setIsRedirectingToStripePortal,
  ] = React.useState(false);
  const [loggingOut, setLoggingOut] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["AUTH_TOKEN"]);
  const location = useLocation<{ from: { pathname: string } }>();
  const history = useHistory();

  if (!userDetails) {
    throw new Error("unable to lookup account details");
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {/* TODO: add the menu
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            */}
          <Typography variant="h6" className={classes.title}>
            Demo Prep
          </Typography>
          {userDetails.user.role !== UserRoleEnum.MANAGER ? null : userDetails
              .account.subscriptionStartDate !== null ? (
            <Button
              disabled={isRedirectingToStripePortal}
              className={classes.toolBarActionButton}
              variant="outlined"
              startIcon={
                isRedirectingToStripePortal && (
                  <CircularProgress
                    size={24}
                    className={classes.circularProgress}
                  />
                )
              }
              onClick={() => {
                setIsRedirectingToStripePortal(true);
                createStripePortalSession(window.location.href).then(
                  (stripePortalSession) => {
                    // redirect to stripe directly
                    setTimeout(() => {
                      window.location.href =
                        stripePortalSession.stripe_portal_session_url;
                      setIsRedirectingToStripePortal(false);
                    }, 500);
                  }
                );
              }}
            >
              Manage Subscription
            </Button>
          ) : (
            /* otherwise show the button to initiate a subscription */
            <Button
              color="secondary"
              variant="outlined"
              component={RouterLink}
              to="/subscription-selection"
            >
              Choose a Subscription
            </Button>
          )}
          {userDetails.user.role === UserRoleEnum.MANAGER && (
            <Button
              className={classes.toolBarActionButton}
              variant="outlined"
              component={RouterLink}
              to="/users-dashboard"
            >
              User Dashboard
            </Button>
          )}
          <Button
            className={classes.toolBarActionButton}
            disabled={loggingOut}
            startIcon={
              loggingOut && (
                <CircularProgress
                  size={24}
                  className={classes.circularProgress}
                />
              )
            }
            onClick={() => {
              setLoggingOut(true);
              setTimeout(function () {
                removeCookie("AUTH_TOKEN");
                dispatch(loggedOut());
                setLoggingOut(false);
              }, 2000);
            }}
            color="inherit"
          >
            {loggingOut ? "Logging Out..." : "Logout"}
          </Button>
        </Toolbar>
      </AppBar>
      {children}
    </>
  );
};

export default withStyles(styles)(DashboardContainer);
