import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { loadStripe } from "@stripe/stripe-js";

import { SubscriptionEnum } from "../types";
import { createStripeCheckoutSession } from "../api_service";

// hack to load stripe once
var STRIPE_PROMISE: Promise<any> | null = null;

type SUBSCRIPTION_INTERVAL = "monthly" | "annually";

const _SUBSCRIPTION_PLAN_FEATURES = {
  [SubscriptionEnum.BASIC]: {
    pricing: {
      monthly: { price: 50.0 },
      annually: { price: 50.0 * 12 - 50.0 },
    },
    features: {
      maxActiveUsers: 5,
      maxActiveDemos: 7,
      maxActiveProducts: 3,
      maxActiveProductFeatures: 5,
      maxRemindersSentPerMonth: 1000,
    },
  },
  [SubscriptionEnum.PRO]: {
    pricing: {
      monthly: { price: 200.0 },
      annually: { price: 200.0 * 12 - 200.0 },
    },
    features: {
      maxActiveUsers: 15,
      maxActiveDemos: 50,
      maxActiveProducts: 8,
      maxActiveProductFeatures: 10,
      maxRemindersSentPerMonth: 7000,
    },
  },
  [SubscriptionEnum.ENTERPRISE]: {
    pricing: {
      monthly: { price: 0.0 },
      annually: { price: 0.0 * 12 - 0.0 },
    },
    features: {
      maxActiveUsers: 100,
      maxActiveDemos: 100,
      maxActiveProducts: 100,
      maxActiveProductFeatures: 100,
      maxRemindersSentPerMonth: 20000,
    },
  },
};

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  form: {
    padding: "2em",
  },
});

const useSubscriptionStyles = makeStyles({
  root: {
    margin: "2em 2em",
    width: "max-content",
    borderRadius: "10",
  },
  title: {
    textTransform: "capitalize",
    textAlign: "center",
  },
  subheader: {
    textAlign: "center",
  },
  actions: {
    justifyContent: "center",
  },
  featureQuantity: {},
  featureLine: { margin: "1.5em 0" },
});

// use a mapping to associate subscription types with feature sets and pricing
function Subscription(props: {
  type: keyof typeof SubscriptionEnum /* keyof typeof XEnum allows us to perform exhaustive checks on strings */;
  annualPricing: boolean;
  checkoutSessionLoading: boolean;
  onSelectSubscription: (
    type: SubscriptionEnum,
    annualPricing: boolean
  ) => void;
}) {
  // cards with sections dedicated to heading/features/pricing/action
  const classes = useSubscriptionStyles();
  const {
    type,
    annualPricing,
    onSelectSubscription,
    checkoutSessionLoading,
  } = props;
  const planInfo = _SUBSCRIPTION_PLAN_FEATURES[type];
  const price = Math.floor(
    annualPricing
      ? planInfo.pricing.annually.price
      : planInfo.pricing.monthly.price
  );
  const denominator = annualPricing ? "/ year" : "/ month";

  return (
    <Card
      className={classes.root}
      /*
      style=
      {{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "2px solid black",
        padding: "20px",
        minWidth: "20ch",
        maxWidth: "20ch",
      }}
      */
    >
      <CardHeader
        title={props.type.toLowerCase()}
        titleTypographyProps={{ className: classes.title }}
        subheader={`$${price > 0 ? price : "?"} ${denominator}`}
        subheaderTypographyProps={{ className: classes.subheader }}
      />
      <Divider variant="middle" />
      <CardContent>
        <Typography className={classes.featureLine}>
          Total Active Users:{" "}
          <span className={classes.featureQuantity}>
            {planInfo.features.maxActiveUsers}
          </span>
        </Typography>
        <Typography className={classes.featureLine}>
          Total Active Demos:{" "}
          <span className={classes.featureQuantity}>
            {planInfo.features.maxActiveDemos}
          </span>
        </Typography>
        <Typography className={classes.featureLine}>
          Total Active Products:{" "}
          <span className={classes.featureQuantity}>
            {planInfo.features.maxActiveProducts}
          </span>
        </Typography>
        <Typography className={classes.featureLine}>
          Total Active Product Features:{" "}
          <span className={classes.featureQuantity}>
            {planInfo.features.maxActiveProductFeatures}
          </span>
        </Typography>
        <Typography className={classes.featureLine}>
          Max Reminders Sent Per Month:{" "}
          <span className={classes.featureQuantity}>
            {planInfo.features.maxRemindersSentPerMonth}
          </span>
        </Typography>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          onClick={() =>
            onSelectSubscription(SubscriptionEnum[type], annualPricing)
          }
          disabled={checkoutSessionLoading}
          fullWidth
        >
          Checkout
        </Button>
      </CardActions>
    </Card>
  );
}

function SubscriptionSelectionContainer(props: {}) {
  const [annualPricing, setAnnualPricing] = React.useState(false);
  const classes = useStyles();
  const [checkoutSessionLoading, setCheckoutSessionLoading] = React.useState(
    false
  );
  const handleCheckout = (
    type: SubscriptionEnum,
    annualPricing: boolean
  ): void => {
    setCheckoutSessionLoading(true);
    createStripeCheckoutSession({
      subscriptionType: type,
      annualPricing,
    }).then((checkout_session) => {
      // convert to async
      // redirect to stripe
      if (!STRIPE_PROMISE) {
        STRIPE_PROMISE = loadStripe(checkout_session.stripe_publishable_key);
      }
      STRIPE_PROMISE.then((stripe) => {
        return stripe.redirectToCheckout({
          sessionId: checkout_session.stripe_checkout_session_id,
        });
      }).then((result) => {
        setCheckoutSessionLoading(false);
      });
    });
  };
  // a toggle will toggle between annual and monthly pricing
  return (
    <div>
      <h1 style={{ textAlign: "center", marginBottom: "1em" }}>
        Choose Your Subscription
      </h1>
      <FormGroup row className={classes.form}>
        <FormControlLabel
          control={
            <Switch
              checked={annualPricing}
              onChange={() => setAnnualPricing(!annualPricing)}
              name="annual-pricing"
            />
          }
          label={`Show ${annualPricing ? "Monthly" : "Annual"} Subscriptions`}
        />
      </FormGroup>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Subscription
          annualPricing={annualPricing}
          type="BASIC"
          onSelectSubscription={handleCheckout}
          checkoutSessionLoading={checkoutSessionLoading}
        />
        <Subscription
          annualPricing={annualPricing}
          type="PRO"
          onSelectSubscription={handleCheckout}
          checkoutSessionLoading={checkoutSessionLoading}
        />
        <Subscription
          annualPricing={annualPricing}
          type="ENTERPRISE"
          onSelectSubscription={handleCheckout}
          checkoutSessionLoading={checkoutSessionLoading}
        />
      </div>
    </div>
  );
}

export default SubscriptionSelectionContainer;
