import config from "./config";
import { listDemosFixture, listUsersFixture } from "./api_service_fixtures";
import {
  DemoFormInput,
  Feature,
  FeatureFormInput,
  OwnerFormInput,
  ProductFormInput,
  AuthFormInput,
  CheckoutSessionFormInput,
  UserInviteRequestFormInput,
  UserInvitationAcceptFormInput,
} from "./types";
import {
  DemosResponse,
  ProductFeaturesResponse,
  OwnersResponse,
  UsersResponse,
  ProductsResponse,
  StripePortalSessionResponse,
  TokenResponse,
  MeResponse,
  StripeCheckoutSessionResponse,
  UserResponse,
  UserResponseItem,
  UserInviteResponse,
} from "./api_service_types";

export enum LoadingStateEnum {
  INIT = 1,
  FETCHING,
  RECEIVED,
  FAILED,
}

/* TODO: hook to initiate calls to services
function useApiService(
  service: string,
  dispatch: any,
  requestData: any
): [LoadingStateEnum, any[], string | null] {
  const [requestState, setRequestState] = React.useState(LoadingStateEnum.INIT);
  return [requestState, [], null];
}

*/

function camelcaser(key: string): string {
  const parts = key.split("_");
  let [initial, ...rest] = parts;
  return (
    initial + rest.map((tok) => tok[0].toUpperCase() + tok.slice(1)).join("")
  );
}

function parsePydanticErrorMessage<T>(response: {
  title: string;
  description: string;
}): { [K in keyof T]?: string } {
  const [header, body] = response.description.split(":");
  console.error(header);
  const error_lines = body.trim().split("\n");
  const fieldErrors: { [key: string]: string } = {};
  if (error_lines.length % 2 !== 0) {
    throw new Error("unexpected error response received");
  }
  for (var i = 0; i < error_lines.length; i += 2) {
    fieldErrors[camelcaser(error_lines[i])] = error_lines[i + 1]
      .trim()
      .split("(type")[0];
  }
  console.error(fieldErrors);
  fieldErrors.__type = "PYDANTIC_ERROR"; // hack to let other code know it's a pydantic error message
  return fieldErrors as { [K in keyof T]?: string };
}

function handleApiResponse<T, R>(response: Response): Promise<R> {
  if (!response.ok) {
    if (response.status === 400 || response.status === 422) {
      // we unwrap the json from the server and then wrap into a promise rejection so that the
      // calling code can just do apiServiceFunc().then().catch(errorMessage => ...)
      return response
        .json()
        .then((json) => Promise.reject(parsePydanticErrorMessage<T>(json)));
    }
    return response.json().then((json) => Promise.reject(json));
  }
  return response.json() as Promise<R>;
}

export function authenticatedFetch(
  endpoint: string,
  requestConfig: RequestInit
): Promise<Response> {
  return fetch(endpoint, {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      Authorization: `Bearer ${config.authToken}`,
    },
  });
}

export function emailAuth(userDetails: AuthFormInput): Promise<TokenResponse> {
  const body = {
    user_email: userDetails.email,
    user_password: userDetails.password,
  };

  return fetch(`${config.serverOrigin}/public/v1/auth_tokens`, {
    mode: "cors",
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  }).then(handleApiResponse) as Promise<TokenResponse>;
}

export function createStripePortalSession(
  return_url: string
): Promise<StripePortalSessionResponse> {
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/stripe_portal_sessions`,
    {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({ return_url }),
      headers: { "Content-Type": "application/json" },
    }
  ).then((resp) =>
    handleApiResponse<{ return_url: string }, StripePortalSessionResponse>(resp)
  );
}

export function createStripeCheckoutSession(
  data: CheckoutSessionFormInput
): Promise<StripeCheckoutSessionResponse> {
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/stripe_checkout_sessions`,
    {
      mode: "cors",
      method: "POST",
      body: JSON.stringify({
        subscription_type: data.subscriptionType.toLowerCase(),
        annual_pricing: data.annualPricing,
      }),
      headers: { "Content-Type": "application/json" },
    }
  ).then((resp) =>
    handleApiResponse<CheckoutSessionFormInput, StripeCheckoutSessionResponse>(
      resp
    )
  );
}

export function listUsers(): Promise<UsersResponse> {
  if (config.useFixtures) {
    return Promise.resolve(listUsersFixture) as Promise<UsersResponse>;
  }
  return authenticatedFetch(`${config.serverOrigin}/public/v1/users`, {
    mode: "cors",
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json() as Promise<UsersResponse>;
  });
}

export function listDemos(): Promise<DemosResponse> {
  if (config.useFixtures) {
    return Promise.resolve(listDemosFixture) as Promise<DemosResponse>;
  }
  return authenticatedFetch(`${config.serverOrigin}/public/v1/demos`, {
    mode: "cors",
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json() as Promise<DemosResponse>;
  });
}

export function deleteDemo(demoId: string): Promise<void> {
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/demos/${demoId}`,
    {
      mode: "cors",
      method: "DELETE",
    }
  ).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
  });
}

export function createDemoReminder(demoId: string): Promise<void> {
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/demos/${demoId}/reminders`,
    {
      mode: "cors",
      method: "POST",
    }
  ).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
  });
}

export function listFeatures(): Promise<Feature[]> {
  return Promise.resolve([]);
}

export function createDemo(
  demoFormState: DemoFormInput
): Promise<DemosResponse> {
  const body = {
    prospect: demoFormState.prospect,
    scheduled_time: demoFormState.scheduledTime,
    location: demoFormState.location,
    notes: demoFormState.notes,
    product_features_uuids: demoFormState.productFeaturesUuids,
    duration_minutes: demoFormState.durationMinutes,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/demos`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => handleApiResponse<DemoFormInput, DemosResponse>(resp));
}

export function createProductFeature(
  featureFormState: FeatureFormInput
): Promise<ProductFeaturesResponse> {
  const body = {
    name: featureFormState.name,
    description: featureFormState.description,
    sales_pitch: featureFormState.salesPitch,
    availability_date: featureFormState.availabilityDate
      .toISOString()
      .replace(/T.*$/, ""),
    owner_uuids: featureFormState.ownerUuids,
  };
  return authenticatedFetch(
    `${config.serverOrigin}/public/v1/products/${featureFormState.productUuid}/features`,
    {
      mode: "cors",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    }
  ).then((resp) =>
    handleApiResponse<FeatureFormInput, ProductFeaturesResponse>(resp)
  );
}

export function createOwner(
  ownerFormState: OwnerFormInput
): Promise<OwnersResponse> {
  const user_uuid = ownerFormState.userUuid?.trim();
  const body = {
    fullname: ownerFormState.fullname,
    email: ownerFormState.email,
    position: ownerFormState.position,
    user_uuid: user_uuid || null,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/owners`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => handleApiResponse<OwnerFormInput, OwnersResponse>(resp));
}

export function createProduct(
  productFormState: ProductFormInput
): Promise<ProductsResponse> {
  const body = {
    name: productFormState.name,
    description: productFormState.description,
    owner_uuid: productFormState.ownerUuid,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/products`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) =>
    handleApiResponse<ProductFormInput, ProductsResponse>(resp)
  );
}

export function getMe(): Promise<MeResponse> {
  return authenticatedFetch(`${config.serverOrigin}/public/v1/me`, {
    mode: "cors",
    method: "GET",
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject(resp.json());
    }
    return resp.json();
  });
}

export function sendUserInvite(
  userInviteFormState: UserInviteRequestFormInput
): Promise<UserResponse> {
  const body = {
    user_fullname: userInviteFormState.userFullname,
    user_role: userInviteFormState.userRole,
    user_email: userInviteFormState.userEmail,
  };
  return authenticatedFetch(`${config.serverOrigin}/public/v1/users`, {
    mode: "cors",
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  }).then((resp) =>
    handleApiResponse<UserInviteRequestFormInput, UserResponse>(resp)
  );
}

export function getUserInvite(
  userInviteToken: string
): Promise<UserInviteResponse> {
  return fetch(
    `${config.serverOrigin}/public/v1/user_invitations/${userInviteToken}`,
    {
      mode: "cors",
      method: "GET",
    }
  ).then((resp) => handleApiResponse<null, UserInviteResponse>(resp));
}

export function acceptUserInvite(
  userInviteToken: string,
  authToken: string,
  userInviteAcceptState: UserInvitationAcceptFormInput
): Promise<UserInviteResponse> {
  const body = {
    user_fullname: userInviteAcceptState.userFullname,
    user_password: userInviteAcceptState.userPassword,
  };
  return fetch(
    `${config.serverOrigin}/public/v1/user_invitations/${userInviteToken}`,
    {
      mode: "cors",
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  ).then((resp) =>
    handleApiResponse<UserInvitationAcceptFormInput, UserInviteResponse>(resp)
  );
}
