/* BEGIN ACTION TYPES these should be moved to another module */
export const DEMO_LIST_RECEIVED = "demos/received";
export const DEMO_DELETED = "demos/deleted";
export const PRODUCT_FEATURES_LIST_RECEIVED = "product_features/received";
export const OWNERS_LIST_RECEIVED = "owners/received";
export const PRODUCTS_LIST_RECEIVED = "products/received";
export const USERS_LIST_RECEIVED = "users/received";
export const AUTH_TOKEN_RECEIVED = "auth/token/received";
export const ME_RECEIVED = "me/received";
export const LOGGED_OUT = "global/logged_out";

export type Optional<T> = T | null;

type ApiValidationError<T> = { [K in keyof T]?: string };

export interface AuthTokenReceivedAction {
  type: typeof AUTH_TOKEN_RECEIVED;
  payload: string;
}

export interface UsersReceivedAction {
  type: typeof USERS_LIST_RECEIVED;
  payload: User[];
}

export interface DemosReceivedAction {
  type: typeof DEMO_LIST_RECEIVED;
  payload: Demo[];
}

export interface DemoDeletedAction {
  type: typeof DEMO_DELETED;
  payload: string;
}

export interface ProductFeaturesReceivedAction {
  type: typeof PRODUCT_FEATURES_LIST_RECEIVED;
  payload: Feature[];
}

export interface OwnersReceivedAction {
  type: typeof OWNERS_LIST_RECEIVED;
  payload: Owner[];
}

export interface ProductsReceivedAction {
  type: typeof PRODUCTS_LIST_RECEIVED;
  payload: Product[];
}

export interface LoggedOutAction {
  type: typeof LOGGED_OUT;
}

export interface MeReceivedAction {
  type: typeof ME_RECEIVED;
  payload: UserDetails;
}

export type ActionType =
  | DemoDeletedAction
  | DemosReceivedAction
  | OwnersReceivedAction
  | ProductFeaturesReceivedAction
  | ProductsReceivedAction
  | AuthTokenReceivedAction
  | UsersReceivedAction
  | LoggedOutAction
  | MeReceivedAction;

/* END ACTION TYPES */

export interface UserDetails {
  user: User;
  account: Account;
}

export enum SubscriptionEnum {
  BASIC = "BASIC",
  PRO = "PRO",
  ENTERPRISE = "ENTERPRISE",
}

export enum StatusEnum {
  ACTIVE = "ACTIVE",
}

export interface Account {
  name: string;
  subscription: SubscriptionEnum;
  status: StatusEnum;
  subscriptionStartDate: Optional<Date>;
  subscriptionEndDate: Optional<Date>;
}

export enum UserRoleEnum {
  MANAGER = "MANAGER",
  SALES = "SALES",
  DEVELOPER = "DEVELOPER",
}

export enum UserStatusEnum {
  PENDING = "PENDING_INVITATION",
  ACTIVE = "ACTIVE",
}

export interface User {
  uuid: string;
  fullname: string;
  email: string;
  role: UserRoleEnum;
  status: UserStatusEnum;
}

export interface Feature {
  name: string;
  uuid: string;
  ownersUuids: string[];
}

export interface Product {
  uuid: string;
  name: string;
  description: string | null;
  productFeatureUuids: string[];
}

export interface Demo {
  uuid: string;
  prospect: string;
  scheduledTime: Date;
  duration_minutes: Optional<number>;
  location: string;
  notes: string;
  productFeaturesUuids: string[] /* the actual product features will be fetched from the store */;
}

export interface Owner {
  uuid: string;
  email: string;
}

export interface AuthFormInput {
  email: string;
  password: string;
}

// rename to DemoFormInput
export interface DemoFormInput {
  prospect: string;
  scheduledTime: Date;
  durationMinutes: Optional<number>;
  location: string;
  productFeaturesUuids: string[];
  notes: string;
}

export interface FeatureFormInput {
  productUuid: string;
  name: string;
  description: string;
  availabilityDate: Date;
  salesPitch: string;
  ownerUuids: string[];
}

export interface OwnerFormInput {
  fullname?: string;
  email?: string;
  position?: string;
  userUuid?: string;
}

export interface ProductFormInput {
  name: string;
  description: string;
  ownerUuid: string;
}

export interface CheckoutSessionFormInput {
  subscriptionType: SubscriptionEnum;
  annualPricing: boolean;
}

export interface UserInviteRequestFormInput {
  userFullname: string;
  userRole: UserRoleEnum;
  userEmail: string;
}

export interface UserInvitationAcceptFormInput {
  userFullname: string;
  userPassword: string;

  // TODO: product/feature uuids that user is an owner of
}

export interface AppStateType {
  features: Feature[];
  demos: Demo[];
  owners: Owner[];
  products: Product[];
  users: User[];
  userDetails?: UserDetails;
}
