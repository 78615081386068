import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { DataGrid } from "@material-ui/data-grid";
import { listUsers, LoadingStateEnum, sendUserInvite } from "../api_service";

import {
  User,
  UserInviteRequestFormInput,
  UserRoleEnum,
  UsersReceivedAction,
} from "../types";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import AppContext from "../app_context";
import { usersReceived } from "../actions";
import { userResponseItemToUser } from "../api_service_data_mappers";

const useStyles = makeStyles({
  root: {},
});

const userInviteStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px",
  },
  formField: {
    margin: "5px",
  },
});

interface userInviteFormState extends UserInviteRequestFormInput {
  errors: { [K in keyof UserInviteRequestFormInput]?: string | null };
}

const userInviteRequestFormInitialValues: userInviteFormState = {
  userFullname: "",
  userEmail: "",
  userRole: UserRoleEnum.DEVELOPER,
  errors: {},
};

type formKeys = keyof UserInviteRequestFormInput;

const userInviteFormReducer = (
  state: userInviteFormState,
  action: {
    name: formKeys | "RESET" | "ERROR";
    value: string | { [k: string]: string | UserRoleEnum };
  }
) => {
  if (action.name === "RESET") {
    return { ...userInviteRequestFormInitialValues };
  }
  if (action.name === "ERROR") {
    return {
      ...state,
      errors: {
        ...state.errors,
        ...(action.value as userInviteFormState["errors"]),
      },
    };
  }

  if (action.name === "userRole") {
    return { ...state, [action.name]: action.value as UserRoleEnum };
  }

  return {
    ...state,
    [action.name]: action.value,
    errors: { ...state.errors, [action.name]: null },
  };
};

function UserInviteForm({
  onSubmitForm,
}: {
  onSubmitForm: (action: UsersReceivedAction) => void;
}) {
  const [userInviteSending, setUserInviteSending] = React.useState(false);
  const [formState, formDispatch] = React.useReducer(
    userInviteFormReducer,
    userInviteRequestFormInitialValues
  );
  const classes = userInviteStyles();
  return (
    <div className={classes.root}>
      <TextField
        className={classes.formField}
        variant="filled"
        id="user-invite-email"
        required
        label="User Email"
        name="user_email"
        value={formState.userEmail}
        error={!!formState.errors.userEmail}
        helperText={formState.errors.userEmail || "coworker@org.com"}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          formDispatch({ name: "userEmail", value: evt.target.value })
        }
      />
      <TextField
        className={classes.formField}
        variant="filled"
        id="user-invite-fullname"
        required
        label="User Name"
        name="user_fullname"
        value={formState.userFullname}
        error={!!formState.errors.userFullname}
        helperText={formState.errors.userFullname || "coworker"}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          formDispatch({ name: "userFullname", value: evt.target.value })
        }
      />
      <FormControl variant="filled">
        <InputLabel id="user-invite-form-role-label">User Role</InputLabel>
        <Select
          className={classes.formField}
          id="user-invite-form-user-role"
          labelId="user-invite-form-user-role-label"
          value={formState.userRole}
          onChange={(evt: React.ChangeEvent<{ value: unknown }>) =>
            formDispatch({
              name: "userRole",
              value: evt.target.value as UserRoleEnum,
            })
          }
          //MenuProps={}
        >
          {Object.keys(UserRoleEnum).map((userRoleType) => (
            <MenuItem key={userRoleType} value={userRoleType}>
              {userRoleType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className={classes.formField}
        variant="contained"
        color="primary"
        disabled={userInviteSending}
        onClick={() => {
          setUserInviteSending(true);
          sendUserInvite(formState)
            .then((userInviteResponse) => {
              onSubmitForm(
                usersReceived(
                  [userInviteResponse.user].map(userResponseItemToUser)
                )
              );
              setUserInviteSending(false);
              formDispatch({ name: "RESET", value: "" });
            })
            .catch((error) => {
              setUserInviteSending(false);
              if (error && error.__type && error.__type === "PYDANTIC_ERROR") {
                // abstract this out!
                formDispatch({
                  name: "ERROR",
                  value: Object.fromEntries(
                    Object.entries(error).filter(([k, v]) => k !== "__type")
                  ) as { [k: string]: string },
                });
              }
            });
        }}
      >
        Send Invite
      </Button>
    </div>
  );
}

function UsersList({ users }: { users: User[] }) {
  let rows = users.map((user) => ({ ...user, id: user.uuid }));
  let columns = [
    { field: "fullname", headerName: "Name", width: 300 },
    { field: "email", headerName: "Email", width: 300 },
    { field: "role", headerName: "Role", width: 200 },
    { field: "status", headerName: "Status", width: 200 },
  ];
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
}

function UserDashboard(props: {}) {
  const classes = useStyles();
  const { state, dispatch } = React.useContext(AppContext);
  const { users: allUsers } = state;
  const [fetchState, setFetchState] = React.useState(
    state.users.length > 0 ? LoadingStateEnum.RECEIVED : LoadingStateEnum.INIT
  );

  // TODO
  // usersService = useApiService()
  // if (usersService.state === FetchStateEnum.LOADING)
  //   show loading
  // // else show error?
  // essentially I want to query the service to see if any part of the app
  // has made a request to it and if the users list has actually been fetched
  React.useEffect(() => {
    if (fetchState !== LoadingStateEnum.INIT) {
      return;
    }
    setFetchState(LoadingStateEnum.FETCHING);
    listUsers().then((usersResponse) => {
      if (usersResponse) {
        setFetchState(LoadingStateEnum.RECEIVED);
        dispatch(
          usersReceived(usersResponse.users.map(userResponseItemToUser))
        );
      } else {
        setFetchState(LoadingStateEnum.FAILED);
        return Promise.reject("unable to fetch users");
      }
    });
  });

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h3">Invite User</Typography>
      <UserInviteForm onSubmitForm={(action) => dispatch(action)} />
      {fetchState === LoadingStateEnum.FETCHING ? (
        <p>loading...</p>
      ) : (
        <div style={{ marginTop: "20px" }}>
          {
            /* if there is only one user do not show this section, instead make inviting primary action */
            allUsers.length <= 1 ? null : (
              <>
                <Typography style={{ marginBottom: "20px" }} variant="h3">
                  All Users
                </Typography>
                <UsersList users={allUsers} />
              </>
            )
          }
        </div>
      )}
    </div>
  );
}

export default UserDashboard;
