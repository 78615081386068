import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useCookies } from "react-cookie";
import { GlobalReducer, initialState } from "./reducer";
import AppContext from "./app_context";
import LoginContainer from "./containers/LoginContainer";
import DemoListContainer from "./containers/DemoListContainer";
import config from "./config";
import { meReceived } from "./actions";
import { userDetailsItemToUserDetails } from "./api_service_data_mappers";
import { getMe } from "./api_service";
import DashboardContainer from "./containers/DashboardContainer";
import SubscriptionSelectionContainer from "./containers/SubscriptionSelectionContainer";
import UserDashboard from "./containers/UsersDashboard";
import UserInvitationContainer from "./containers/UserInvitationContainer";

//fetch
function App() {
  const [state, dispatch] = React.useReducer(GlobalReducer, initialState);
  const [cookies] = useCookies(["AUTH_TOKEN"]);

  if (cookies.AUTH_TOKEN) {
    config.authToken = cookies.AUTH_TOKEN;
  }

  React.useEffect(() => {
    async function _inner() {
      let meResponse;
      try {
        meResponse = await getMe();
      } catch (err) {
        console.error("Unable to get user details: contact support");
      }
      if (!meResponse) {
        // logerror
        return;
      }
      dispatch(meReceived(userDetailsItemToUserDetails(meResponse)));
    }
    _inner();
  }, [cookies.AUTH_TOKEN]);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router>
        <Switch>
          <Route
            path="/auth"
            render={({ location }) => {
              if (cookies.AUTH_TOKEN) {
                return (
                  <Redirect to={{ pathname: "/", state: { from: location } }} />
                );
              } else {
                return <LoginContainer />;
              }
            }}
          />
          {/* routing should be nested depending on auth state to avoid duplicate code as below*/}
          <Route
            exact
            path="/subscription-selection"
            render={({ location }) => {
              if (cookies.AUTH_TOKEN) {
                // abstract to another component
                if (!state.userDetails) {
                  return <h1>logging in...</h1>;
                }
                return (
                  <DashboardContainer>
                    <SubscriptionSelectionContainer />
                  </DashboardContainer>
                );
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/auth", state: { from: location } }}
                  />
                );
              }
            }}
          />
          <Route
            exact
            path="/users-dashboard"
            render={({ location }) => {
              if (cookies.AUTH_TOKEN) {
                // abstract to another component
                if (!state.userDetails) {
                  return <h1>logging in...</h1>;
                }
                return (
                  <DashboardContainer>
                    <UserDashboard />
                  </DashboardContainer>
                );
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/auth", state: { from: location } }}
                  />
                );
              }
            }}
          />
          <Route
            exact
            path="/accept-invitation"
            render={({ location }) => {
              let query = new URLSearchParams(location.search);
              let token = query.get("token");
              if (!token) {
                return <Redirect to={{ pathname: "/" }} />;
              }
              // abstract to another component
              if (state.userDetails) {
                return <Redirect to={{ pathname: "/" }} />;
              } else {
                return <UserInvitationContainer inviteToken={token} />;
              }
            }}
          />
          <Route
            path="/"
            exact
            render={({ location }) => {
              if (cookies.AUTH_TOKEN) {
                // abstract to another component
                if (!state.userDetails) {
                  return <h1>logging in...</h1>;
                }
                return (
                  <DashboardContainer>
                    <DemoListContainer />
                  </DashboardContainer>
                );
              } else {
                return (
                  <Redirect
                    to={{ pathname: "/auth", state: { from: location } }}
                  />
                );
              }
            }}
          />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
