import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

import { emailAuth, getMe } from "../api_service";
import AppContext from "../app_context";
import { meReceived } from "../actions";
import { userDetailsItemToUserDetails } from "../api_service_data_mappers";

const loginStyles = (theme: Theme) =>
  createStyles({
    loginContainer: {
      position: "absolute",
      height: "100%",
    },
    form: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    formField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: "300px",
    },
  });

interface Props extends WithStyles<typeof loginStyles> {}

function LoginFormContainer(props: Props) {
  const { classes } = props;
  const { state, dispatch } = useContext(AppContext);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [cookies, setCookie] = useCookies(["AUTH_TOKEN"]);
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();

  const { from } = location.state || { from: { pathname: "/" } };
  const formSubmitHandler = async () => {
    // take care of submitting form
    setFormIsSubmitting(true);
    setEmailError("");
    setPasswordError("");

    let token;
    try {
      token = await emailAuth({ email, password }).then((tokenResponse) => {
        setCookie("AUTH_TOKEN", tokenResponse.token, {
          path: "/",
          maxAge: 3600,
        });
        return tokenResponse.token;
      });
    } catch (err) {
      setEmailError("Incorrect email or password provided");
      setFormIsSubmitting(false);
      return null;
    }
    if (!token) {
      // logerror
      console.error("unable to retrieve login token");
      return;
    }

    setFormIsSubmitting(false);
    history.replace(from);
  };

  return (
    <Grid
      className={classes.loginContainer}
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={2}
    >
      <Typography gutterBottom color="primary" variant="h2" component="h2">
        DemoPrep
      </Typography>
      {/* login form requires email/password */}
      <form
        className={classes.form}
        onKeyPress={(evt) => {
          if (evt.key === "Enter") {
            formSubmitHandler();
          }
        }}
      >
        <TextField
          className={classes.formField}
          variant="filled"
          id="login-form-email"
          required
          label="Email"
          value={email}
          placeholder="email@yourcompany.com"
          error={Boolean(emailError)}
          helperText={emailError || "Enter your email"}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(evt.target.value)
          }
        />
        <TextField
          className={classes.formField}
          variant="filled"
          id="login-form-password"
          required
          label="Password"
          type="password"
          value={password}
          error={Boolean(passwordError)}
          helperText={passwordError || "Enter your password"}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(evt.target.value)
          }
        />
        <Button
          fullWidth={true}
          variant="contained"
          color="primary"
          disabled={formIsSubmitting}
          onClick={formSubmitHandler}
        >
          Login
        </Button>
      </form>
    </Grid>
  );
}

export default withStyles(loginStyles)(LoginFormContainer);
