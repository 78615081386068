function toVal(mix: any): string {
  var k,
    y,
    str = "";
  if (mix) {
    if (typeof mix === "object") {
      if (Array.isArray(mix)) {
        for (k = 0; k < mix.length; k++) {
          if (mix[k] && (y = toVal(mix[k]))) {
            str && (str += " ");
            str += y;
          }
        }
      } else {
        for (k in mix) {
          if (mix[k] && (y = toVal(k))) {
            str && (str += " ");
            str += y;
          }
        }
      }
    } else if (typeof mix !== "boolean" && !mix.call) {
      str && (str += " ");
      str += mix;
    }
  }
  return str;
}

export function classnames<TS extends any[]>(...args: TS): string {
  var value,
    str = "";
  args.forEach((d) => {
    value = toVal(d);
    if (!!value) {
      str = str ? `${str} ${value}` : value;
    }
  });
  return str;
}
