import {
  AppStateType,
  ActionType,
  PRODUCT_FEATURES_LIST_RECEIVED,
  OWNERS_LIST_RECEIVED,
  DEMO_LIST_RECEIVED,
  PRODUCTS_LIST_RECEIVED,
  USERS_LIST_RECEIVED,
  DEMO_DELETED,
  LOGGED_OUT,
  ME_RECEIVED,
} from "./types";

export const initialState = {
  owners: [],
  features: [],
  demos: [],
  products: [],
  users: [],
};

function mergeArraysByUniqueIdentifiers<ArrayItem, K extends keyof ArrayItem>(
  original: ArrayItem[],
  update: ArrayItem[],
  identifier: (item: ArrayItem) => ArrayItem[K]
): ArrayItem[] {
  let result: ArrayItem[] = [...original];
  update.forEach((item) => {
    let identity = identifier(item);
    if (!result.find((existingItem) => identifier(existingItem) === identity)) {
      result.push(item);
    }
  });
  return result;
}

export function GlobalReducer(
  state: AppStateType,
  action: ActionType
): AppStateType {
  console.log(state);
  switch (action.type) {
    case DEMO_LIST_RECEIVED:
      return {
        ...state,
        demos: mergeArraysByUniqueIdentifiers(
          state.demos,
          action.payload,
          (item) => item.uuid
        ),
      };
    case DEMO_DELETED:
      return {
        ...state,
        demos: state.demos.filter((demo) => demo.uuid !== action.payload),
      };
    case OWNERS_LIST_RECEIVED:
      return {
        ...state,
        owners: mergeArraysByUniqueIdentifiers(
          state.owners,
          action.payload,
          (item) => item.uuid
        ),
      };
    case PRODUCT_FEATURES_LIST_RECEIVED:
      return {
        ...state,
        features: mergeArraysByUniqueIdentifiers(
          state.features,
          action.payload,
          (item) => item.uuid
        ),
      };
    case PRODUCTS_LIST_RECEIVED:
      return {
        ...state,
        products: mergeArraysByUniqueIdentifiers(
          state.products,
          action.payload,
          (item) => item.uuid
        ),
      };
    case USERS_LIST_RECEIVED:
      return {
        ...state,
        users: mergeArraysByUniqueIdentifiers(
          state.users,
          action.payload,
          (item) => item.uuid
        ),
      };

    case LOGGED_OUT:
      return { ...initialState };
    case ME_RECEIVED:
      return { ...state, userDetails: action.payload };
    default:
      console.error(`${action} is not handled!`);
      return state;
  }
}
