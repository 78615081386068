import React, { useState, useReducer, useContext } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";

import AppContext from "../app_context";
import { productFeaturesReceived } from "../actions";
import { FeatureFormInput } from "../types";
import { createProductFeature } from "../api_service";
import { featureResponseItemToFeature } from "../api_service_data_mappers";
import OwnerCreateFormContainer from "./OwnerCreateFormContainer";
import ChipMultiSelect from "../components/ChipMultiSelect";
import ProductCreateFormContainer from "./ProductCreateFormContainer";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const featureCreateFormStyles = (theme: Theme) =>
  createStyles({
    ownerSelectionContainer: {
      display: "flex",
      alignItems: "baseline",
    },
    ownerChip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[700],
      color: "#ffffff",
    },
    ownerChipContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    productSelectionContainer: {
      display: "flex",
      alignItems: "baseline",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      marginLeft: 2,
    },
    formField: {
      margin: 20,
      width: "90%",
    },
    paper: {
      width: 700,
      padding: "12px",
      margin: "auto",
      overflow: "scroll",
      maxHeight: 600,
      minHeight: 500,
      marginTop: "50px",
    },
  });
interface FeatureCreateFormProps
  extends WithStyles<typeof featureCreateFormStyles> {
  open: boolean;
  onCloseModal: () => void;
  onSubmitForm: (newFeatureUuid: string) => void;
}

interface featureFormState extends FeatureFormInput {
  errors: { [K in keyof FeatureFormInput]?: string | null };
}

const featureFormInitialValues: featureFormState = {
  productUuid: "",
  name: "",
  description: "",
  availabilityDate: new Date(),
  salesPitch: "",
  ownerUuids: [],
  errors: {},
};

type formKeys = keyof FeatureFormInput;

const featureFormReducer = (
  state: featureFormState,
  action: {
    name: formKeys | "RESET" | "ERROR";
    value: string | string[] | { [k: string]: string };
  }
) => {
  if (action.name === "RESET") {
    return { ...featureFormInitialValues };
  }

  if (action.name === "ERROR") {
    return {
      ...state,
      errors: { ...state.errors, ...(action.value as { [k: string]: string }) },
    };
  }

  if (action.name === "ownerUuids") {
    return { ...state, ownerUuids: action.value as string[] };
  }

  const value = action.value as string;

  if (action.name === "availabilityDate") {
    return {
      ...state,
      availabilityDate: new Date(value),
      errors: { ...state.errors, [action.name]: null },
    };
  }
  return {
    ...state,
    [action.name]: value,
    errors: { ...state.errors, [action.name]: null },
  };
};

const FeatureCreateFormContainer: React.FC<FeatureCreateFormProps> = ({
  open,
  onCloseModal,
  onSubmitForm,
  classes,
}) => {
  const [formState, formDispatch] = useReducer(
    featureFormReducer,
    featureFormInitialValues
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [isOwnerFormOpen, setIsOwnerFormOpen] = useState(false);
  const [isProductFormOpen, setIsProductFormOpen] = useState(false);
  if (!open) {
    return null;
  }
  const { owners, products } = state;
  return (
    <>
      <Dialog
        open={open}
        onClose={onCloseModal}
        scroll="paper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle disableTypography={true} id="feature-create-form-title">
          <Typography variant="h4" component="h4">
            Add A Product Feature
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form className={classes.form}>
            <div className={classes.productSelectionContainer}>
              <FormControl variant="filled" className={classes.formField}>
                <InputLabel id="feature-create-form-product-label">
                  Product
                </InputLabel>
                <Select
                  id="feature-create-form-product"
                  labelId="feature-create-form-product-label"
                  value={formState.productUuid}
                  onChange={(evt: React.ChangeEvent<{ value: unknown }>) =>
                    formDispatch({
                      name: "productUuid",
                      value: evt.target.value as string,
                    })
                  }
                  MenuProps={SelectMenuProps}
                >
                  {products.map((product) => (
                    <MenuItem key={product.uuid} value={product.uuid}>
                      {product.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={() => setIsProductFormOpen(true)}
                fullWidth={false}
                color="default"
                variant="text"
                disabled={isFormSubmitting}
              >
                Add Product
              </Button>
            </div>
            <TextField
              variant="filled"
              id="feature-create-form-name"
              required
              className={classes.formField}
              label="Name"
              name="name"
              value={formState.name}
              error={!!formState.errors.name}
              helperText={
                formState.errors.name || "How do you refer to the feature?"
              }
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                formDispatch({ name: "name", value: evt.target.value })
              }
            />
            <TextField
              id="feature-create-form-availability-date"
              variant="filled"
              required
              label="Availability Date"
              className={classes.formField}
              value={formState.availabilityDate
                .toISOString()
                .replace(/T.*$/, "")}
              error={!!formState.errors.availabilityDate}
              helperText={
                formState.errors.availabilityDate ||
                "will this feature be ready soon?"
              }
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                formDispatch({
                  name: "availabilityDate",
                  value: evt.target.value,
                });
              }}
            />
            <TextField
              id="feature-create-form-description"
              variant="filled"
              //required
              label="Description"
              className={classes.formField}
              value={formState.description}
              error={!!formState.errors.description}
              helperText={
                formState.errors.description ||
                "How would you describe this feature?"
              }
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                formDispatch({ name: "description", value: evt.target.value });
              }}
            />
            <TextField
              id="feature-create-form-sales-pitch"
              variant="filled"
              label="Sales Pitch"
              multiline
              rowsMax={4}
              className={classes.formField}
              value={formState.salesPitch}
              error={!!formState.errors.salesPitch}
              helperText={formState.errors.salesPitch || "What's the pitch?"}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                formDispatch({ name: "salesPitch", value: evt.target.value })
              }
            />
            {/* the feature selection field, could be made into its own component? */}
            <div className={classes.ownerSelectionContainer}>
              <ChipMultiSelect
                label="Owners"
                id="feature-create-form-owners"
                value={formState.ownerUuids}
                onChange={(evt: React.ChangeEvent<{ value: unknown }>) =>
                  formDispatch({
                    name: "ownerUuids",
                    value: evt.target.value as string[],
                  })
                }
                objects={owners}
                labelLookup={(owner) => (owner ? owner.email : "")}
              />
              <Button
                onClick={() => setIsOwnerFormOpen(true)}
                fullWidth={false}
                color="default"
                variant="text"
                disabled={isFormSubmitting}
              >
                Add Owner
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={false}
            variant="outlined"
            color="secondary"
            disabled={isFormSubmitting}
            onClick={() => {
              /* TODO: hackish way of clearing form! */
              formDispatch({ name: "RESET", value: "" });
              onCloseModal();
            }}
          >
            Go Back
          </Button>
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            disabled={isFormSubmitting}
            onClick={() => {
              setIsFormSubmitting(true);
              createProductFeature(formState)
                .then((featureResponse) => {
                  dispatch(
                    productFeaturesReceived(
                      featureResponse.product_features.map(
                        featureResponseItemToFeature
                      )
                    )
                  );
                  onSubmitForm(featureResponse.product_features[0].uuid);
                  setIsFormSubmitting(false);
                  formDispatch({ name: "RESET", value: "" });
                })
                .catch((error) => {
                  setIsFormSubmitting(false);
                  formDispatch({
                    name: "ERROR",
                    value: Object.fromEntries(
                      Object.entries(error).filter(([k, v]) => k !== "__type")
                    ) as { [k: string]: string },
                  });
                });
            }}
          >
            Add Feature
          </Button>
        </DialogActions>
      </Dialog>
      <OwnerCreateFormContainer
        open={isOwnerFormOpen}
        onCloseModal={() => setIsOwnerFormOpen(false)}
        onSubmitForm={(ownerUuid) => {
          formDispatch({
            name: "ownerUuids",
            value: [...formState.ownerUuids, ownerUuid],
          });
          setIsOwnerFormOpen(false);
        }}
      />
      <ProductCreateFormContainer
        open={isProductFormOpen}
        onCloseModal={() => setIsProductFormOpen(false)}
        onSubmitForm={(productUuid) => {
          formDispatch({
            name: "productUuid",
            value: productUuid,
          });
          setIsProductFormOpen(false);
        }}
      />
    </>
  );
};

export default withStyles(featureCreateFormStyles)(FeatureCreateFormContainer);
