import React, { useState, useReducer, useContext } from "react";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import { createDemo } from "../api_service";
import { DemoFormInput } from "../types";
import AppContext from "../app_context";
import { demosReceived } from "../actions";
import { demoResponseItemToDemo } from "../api_service_data_mappers";
import FeatureCreateFormContainer from "./FeatureCreateFormContainer";
import ChipMultiSelect from "../components/ChipMultiSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const demoFormStyles = (theme: Theme) =>
  createStyles({
    featureSelectionContainer: {
      display: "flex",
      alignItems: "baseline",
    },
    featureChip: {
      margin: theme.spacing(0.5),
      backgroundColor: theme.palette.grey[700],
      color: "#ffffff",
    },
    featureChipContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      marginLeft: 2,
    },
    formField: {
      margin: 20,
      width: "90%",
    },
    paper: {
      width: 700,
      padding: "12px",
      margin: "auto",
      overflow: "scroll",
      maxHeight: 600,
      minHeight: 500,
      marginTop: "50px",
    },
  });

interface DemoCreateFormProps extends WithStyles<typeof demoFormStyles> {
  open: boolean;
  onCloseModal: () => void;
  onSubmitForm: () => void;
}

interface demoFormState extends DemoFormInput {
  errors: { [K in keyof DemoFormInput]?: string | null };
}

const demoFormInitialValues: demoFormState = {
  prospect: "",
  scheduledTime: new Date(),
  location: "",
  durationMinutes: null,
  productFeaturesUuids: [],
  notes: "",
  errors: {},
};
type formKeys = keyof DemoFormInput;

const demoFormReducer = (
  state: demoFormState,
  action: {
    name: formKeys | "RESET" | "ERROR";
    value: string | string[] | { [k: string]: string };
  }
) => {
  if (action.name === "RESET") {
    return { ...demoFormInitialValues };
  }
  if (action.name === "productFeaturesUuids") {
    return {
      ...state,
      productFeaturesUuids: action.value as string[],
      errors: { ...state.errors, productFeaturesUuids: null },
    };
  }

  if (action.name === "ERROR") {
    return {
      ...state,
      errors: { ...state.errors, ...(action.value as demoFormState["errors"]) },
    };
  }
  //  TODO(ak): need to fix this error
  const value = action.value as string;
  if (action.name === "scheduledTime") {
    let scheduledTime;

    try {
      scheduledTime = new Date(value);
      return {
        ...state,
        scheduledTime,
        errors: { ...state.errors, scheduledTime: null },
      };
    } catch (err) {
      return {
        ...state,
        errors: { ...state.errors, scheduledTime: "Invalid time" },
      };
    }
  }
  return {
    ...state,
    [action.name]: value,
    errors: { ...state.errors, [action.name]: null },
  };
};
function theDate(date: Date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let strmonth = "";
  let strday = "";
  let strhours = "";
  let strmin = "";

  if (month < 10) {
    strmonth = "0" + month;
  } else {
    strmonth = "" + month;
  }

  if (day < 10) {
    strday = "0" + day;
  } else {
    strday = "" + day;
  }
  if (hours < 10) {
    strhours = "0" + hours;
  } else {
    strhours = "" + hours;
  }
  if (minutes < 10) {
    strmin = "0" + minutes;
  } else {
    strmin = "" + minutes;
  }

  let fullDate =
    year + "-" + strmonth + "-" + strday + "T" + strhours + ":" + strmin;
  return fullDate;
}

const DemoCreationModalContainer: React.FC<DemoCreateFormProps> = ({
  open,
  onCloseModal,
  onSubmitForm,
  classes,
}) => {
  const [formState, formDispatch] = useReducer(
    demoFormReducer,
    demoFormInitialValues
  );
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isNestedFormOpen, setIsNestedFormOpen] = useState(false);

  /* use context to bring in features into this component from the global scope */
  const { state, dispatch } = useContext(AppContext);
  const { features } = state;
  if (!open) {
    return null;
  }

  return (
    <>
      <FeatureCreateFormContainer
        open={isNestedFormOpen}
        onCloseModal={() => setIsNestedFormOpen(false)}
        onSubmitForm={(featureUuid: string) => {
          // this should return the product feature created
          formDispatch({
            name: "productFeaturesUuids",
            value: [...formState.productFeaturesUuids, featureUuid],
          });
          setIsNestedFormOpen(false);
        }}
      />
      <Dialog
        open={open}
        onClose={onCloseModal}
        fullWidth={true}
        scroll="paper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle disableTypography={true} id="demo-create-form-title">
          <Typography variant="h4" component="h4">
            Schedule New Demo
          </Typography>
        </DialogTitle>

        <DialogContent>
          <form className={classes.form}>
            <TextField
              variant="filled"
              id="demo-create-form-prospect"
              required
              className={classes.formField}
              label="Prospect"
              value={formState.prospect}
              error={!!formState.errors.prospect}
              helperText={
                formState.errors.prospect || "Who is demo to be scheduled with?"
              }
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                formDispatch({ name: "prospect", value: evt.target.value })
              }
            />
            <TextField
              id="demo-create-form-scheduled-time"
              variant="filled"
              required
              label="Scheduled Time"
              className={classes.formField}
              value={theDate(formState.scheduledTime)}
              error={!!formState.errors.scheduledTime}
              helperText={formState.errors.scheduledTime || "When is the demo?"}
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                formDispatch({
                  name: "scheduledTime",
                  value: evt.target.value,
                });
              }}
            />
            <FormControl className={classes.formField} variant="filled">
              <InputLabel id="duration">Duration</InputLabel>
              <Select
                id="duration"
                variant="filled"
                label="Duration"
                onChange={(evt) => {
                  formDispatch({
                    name: "durationMinutes",
                    value: evt.target.value as string,
                  });
                }}
                value={
                  formState.durationMinutes === null
                    ? ""
                    : formState.durationMinutes
                }
              >
                {Array(60 / 5)
                  .fill(1)
                  .map((_, i) => (i + 1) * 5)
                  .map((d) => (
                    <MenuItem key={d} value={d}>
                      {d} minutes
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              id="demo-create-form-location"
              variant="filled"
              required
              label="Location"
              className={classes.formField}
              value={formState.location}
              error={!!formState.errors.location}
              helperText={
                formState.errors.location ||
                "Where is the demo located? Online or Onsite?"
              }
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                formDispatch({ name: "location", value: evt.target.value });
              }}
            />
            <TextField
              id="demo-create-form-notes"
              variant="filled"
              label="Demo Notes"
              multiline
              rowsMax={4}
              className={classes.formField}
              value={formState.notes}
              error={!!formState.errors.notes}
              helperText={formState.errors.notes || "Jot down a game plan"}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                formDispatch({ name: "notes", value: evt.target.value })
              }
            />
            {/* the feature selection field, could be made into its own component? */}
            <div className={classes.featureSelectionContainer}>
              <ChipMultiSelect
                label="Product Features"
                id="demo-create-form-features"
                value={formState.productFeaturesUuids}
                error={formState.errors.productFeaturesUuids}
                onChange={(evt: React.ChangeEvent<{ value: unknown }>) =>
                  formDispatch({
                    name: "productFeaturesUuids",
                    value: evt.target.value as string[],
                  })
                }
                objects={features}
                labelLookup={(feature) => (feature ? feature.name : "")}
              />
              <Button
                onClick={() => setIsNestedFormOpen(true)}
                fullWidth={false}
                color="default"
                variant="text"
                disabled={isFormSubmitting}
              >
                Add Feature
              </Button>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={false}
            variant="contained"
            color="secondary"
            disabled={isFormSubmitting}
            onClick={() => {
              /* TODO: hackish way of clearing form! */
              formDispatch({ name: "RESET", value: "" });
              onCloseModal();
            }}
          >
            Cancel
          </Button>
          <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            disabled={isFormSubmitting}
            onClick={() => {
              setIsFormSubmitting(true);
              createDemo(formState)
                .then((demoResponse) => {
                  dispatch(
                    demosReceived(
                      demoResponse.demos.map(demoResponseItemToDemo)
                    )
                  );
                  setIsFormSubmitting(false);
                  onSubmitForm();
                  formDispatch({ name: "RESET", value: "" });
                })
                .catch((error) => {
                  setIsFormSubmitting(false);
                  if (
                    error &&
                    error.__type &&
                    error.__type === "PYDANTIC_ERROR"
                  ) {
                    formDispatch({
                      name: "ERROR",
                      value: Object.fromEntries(
                        Object.entries(error).filter(([k, v]) => k !== "__type")
                      ) as { [k: string]: string },
                    });
                  }
                });
            }}
          >
            Schedule It!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(demoFormStyles)(DemoCreationModalContainer);
