let config: {
  serverOrigin: string;
  authToken: string;
  useFixtures: boolean;
};

let settings = process.env;
console.log(settings);

if (process.env.NODE_ENV === "production") {
  config = {
    serverOrigin:
      process.env.REACT_APP_SERVER_ORIGIN || "https://placeholder.demoprep.app",
    authToken: "",
    useFixtures: false,
  };
} else if (process.env.NODE_ENV === "development") {
  config = {
    serverOrigin:
      process.env.REACT_APP_SERVER_ORIGIN ||
      "https://demoprep-dev.herokuapp.com",
    authToken: "",
    useFixtures: false,
  };
} else {
  // unknown?
  config = {
    serverOrigin: "",
    authToken: "",
    useFixtures: false,
  };
}

export default config;
